const base_url = 'https://api.wg996.top' // 接口地址
const upload_url = base_url + '/api/system/file'
const login_url = '/pages/login/index' // 登录地址
const ws_url = 'wss://api.wg996.top/ws' // websocket地址

const get_urls = {
	
}

const post_urls = {
	// 登录
	login: '/api/user/login', // 登录
	register: '/api/user/register', // 注册
	reset: '/api/user/reset', // 忘记密码（未登录）
	reset_auth: '/api/user/resetAuth', //重设密码（已登录）
	captcha: '/api/system/captcha', // 获取注册动态验证码
	sendSms: '/api/sms/send', // 发送验证码
	logout:'/api/user/logout', // 退出登录
	
	// 配置
    setting_lang: '/api/system/setting_lang', // 获取语言包配置
	setting: '/api/system/setting', // 获取配置
    
    // 全局
    home: '/api/system/home', // 获取首页信息
    announcement: '/api/system/announcement', // 获取首页公告
    product: '/api/product', // 获取产品
    
    // 交易
    order_book: '/api/product/depth', // 获取买卖盘
    latest_record: '/api/product/trade', // 获取最新交易
    
    // 永续合约
    perpetualOrderCreate: '/api/perpetualOrder/create', // 购买
    perpetualOrderRecord: '/api/perpetualOrder/record', // 记录
    perpetualOrderClose: '/api/perpetualOrder/close', // 平仓
    changeOrderPrice: '/api/perpetualOrder/changeOrderPrice', // 修改止盈止损
    
    // 币币交易
    tradeOrderCreate: '/api/tradeOrder/create', // 购买
    tradeOrderRecord: '/api/tradeOrder/record', // 记录
    
    // 秒合约
    secondOrderCreate: '/api/secondOrder/create', // 购买
    secondOrderRecord: '/api/secondOrder/record', // 记录
    
    // 理财
    financeList: '/api/finance/list', // 列表
    financeDetail: '/api/finance/detail', // 详情
    financeHistory: '/api/finance/history', // 记录
    financeCreate: '/api/finance/create', // 购买

    // 用户
    user_info: '/api/user/userInfo', // 获取用户信息
    set_user_info: '/api/user/setUserInfo', // 设置用户信息
    bind_account: '/api/account/bind', // 设置个人信息
    get_account: '/api/account/get', //获取个人信息
    credit: '/api/credit', // 资金统计
    frozen: '/api/user/frozen', // 获取冻结
    signin: '/api/user/signin', // 签到
    credit_logs: '/api/credit/logs', // 资金详情
    login_log: '/api/user/login_log', // 登录历史
    set_pay_pass: '/api/user/pay_pass', // 设置/重置交易密码
    getGoogle: '/api/user/getGoogle', // 获取谷歌密钥
    bindGoogle: '/api/user/bindGoogle', // 绑定谷歌
    removeGoogle: '/api/user/deleteGoogle', // 移除谷歌
    
	// 充值 & 提现
    get_address: '/api/userCreditOrder/getAddress', // 获取充值信息
    recharge_list: '/api/userCreditOrder/recharge_list', // 获取充值记录
    wfee: '/api/userCreditOrder/wfee', // 获取提现手续费
    withdraw: '/api/userCreditOrder/withdraw', // 提现
    withdraw_cancel: '/api/userCreditOrder/withdrawConfirm', // 取消提现
    withdraw_list: '/api/userCreditOrder/withdraw_list', // 获取提现记录
}

export {
	base_url,
	upload_url,
    login_url,
    get_urls,
    post_urls,
    ws_url
}
