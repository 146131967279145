export default {
    "Module": {
        "Home": "Lar",
        "PerpetualContract": "Futuros perpétuos",
        "CurrencyTrading": "Transação de moeda",
        "SecondContract": "Contrato de opções",
        "Finance": "Finança",
        "Announcement": "Anúncio",
        "Assets": "Ativos",
        "Account": "Conta"
    },
    "Login": {
        "Login": "Conecte-se",
        "Register": "Registro",
        "RetrievePassword": "Recuperar Senha",
        "Type": "E-mail",
        "Email": "E-mail",
        "Password": "Senha",
        "ConfirmPassword": "Confirme sua senha",
        "VerificationCode": "Código de verificação",
        "Send": "Enviar",
        "PhoneNumber": "Número de telefone",
        "InvitationCode": "Código de Convite",
        "Agree": "eu li e concordo",
        "UserAgreement": "Termo de Acordo do Usuário",
        "EnterEmail": "Por favor introduza o seu e-mail",
        "EnterEmailError": "Insira o formato de e-mail correto",
        "EnterPassword": "Por favor, insira sua senha",
        "EnterPasswordError": "O formato da senha é de 6 a 20 caracteres",
        "EnterConfirmPassword": "Por favor, confirme sua senha",
        "EnterConfirmPasswordError": "As senhas inseridas duas vezes são inconsistentes",
        "EnterEmailFirst": "Por favor, digite seu e-mail primeiro",
        "EnterVerificationCode": "por favor insira o código de verificação",
        "EnterPhoneNumber": "Por favor insira o seu número de telemóvel",
        "EnterInvitationCode": "Por favor insira o código de convite",
        "ConfirmAgreement": "Por favor, leia e concorde com o Acordo do Usuário",
        "Forget": "Esqueceu a senha?"
    },
    "Home": {
        "TcTitle": "Notificação",
        "TopTitle1": "Inicie seu investimento em criptomoedas rapidamente",
        "TopTitle2": "Activtrades é o lugar mais confiável e seguro para comprar e vender criptomoedas",
        "EmailAddress": "Endereço de email",
        "StartUsing": "começar a usar",
        "SeeMore": "Ver mais",
        "MarketTrend": "Tendência de mercado",
        "Currency": "Moeda",
        "LatestPrice": "preço mais recente",
        "Change": "mudança de 24H",
        "TradingVolume": "Volume de negociação de 24H",
        "Trend": "Tendência",
        "Operate": "Operar",
        "Trade": "Troca",
        "AllTradingVolume": "Volume de negociação de câmbio de 24H",
        "SupportedCountries": "Países suportados",
        "RegisterNumber": "Número de usuários registrados",
        "Box4Title": "Plataforma de criptomoeda mais confiável",
        "Box4Desc": "Aqui estão as razões pelas quais você deve escolher o Activtrades",
        "Box4TabTitles": [
            "Transações diversificadas",
            "Taxa mínima",
            "Segurança líder",
            "Experiência de usuário final"
        ],
        "Box4TabDescs": [
            "A Activtrades fornece uma carteira de pedidos com alta liquidez, negociação rica em criptomoedas e serviços de gerenciamento de ativos para atender às diversas necessidades de negociação.",
            "Ao fornecer aos traders acesso ao mercado de financiamento ponto a ponto, a Activtrades escolhe pares de negociação à vista de criptomoedas específicos com as taxas de transação mais baixas.",
            "O Activtrades adota um método de armazenamento offline e os dados de transações pessoais são garantidos por meio de criptografia de ponta a ponta, e a plataforma monitora em tempo real para proteger totalmente a segurança dos ativos do usuário.",
            "Usuário em primeiro lugar, fácil de usar, ouvindo feedback, otimização rápida do produto, 7 * 24 horas em tempo real on-line, serviços profissionais multilíngues individuais."
        ],
        "Box5Title": "Linhas de produtos em constante enriquecimento para atender às necessidades de vários usuários",
        "Box5TabTitles": [
            "Futuros perpétuos",
            "Transação de moeda",
            "Contrato de opções",
            "Finança"
        ],
        "Box5TabDescs": [
            "Negociação de alavancagem, usando alavancagem para usar seus próprios fundos como margem, emprestar N vezes os fundos da plataforma como principal e fazer investimentos para obter lucros.",
            "Use uma determinada moeda digital como unidade de preço, compre outros tipos de moeda digital, compre e venda rapidamente criptomoedas ao melhor preço e obtenha os melhores retornos.",
            "Derivativos financeiros, analisando as condições de mercado de curto prazo, os comerciantes compram ou vendem a um preço predeterminado em um momento específico e negociam em uma frequência rápida para obter lucros.",
            "Alta taxa de retorno anualizada competitiva, garantia de segurança de capital de primeira classe, fácil de obter renda!"
        ],
        "TradeNow": "Negocie agora",
        "Box6Title1": "Inicie transações a qualquer hora, em qualquer lugar",
        "Box6Title2": "Ambos APP e WEB podem abrir transações rapidamente",
        "FooterItems1": [
            "Termos e Políticas",
            "taxa padrão",
            "Aviso de risco",
            "Contra lavagem de dinheiro",
            "Sobre nós"
        ],
        "FooterItems2": [
            "Privacidade do serviço",
            "Termos de serviço",
            "Declaração de privacidade",
            "Papel branco",
            "Certificação MSB"
        ],
        "FooterItems3": [
            "Centro de ajuda",
            "Guia do iniciante",
            "Guia de operação",
            "Contate-nos"
        ]
    },
    "Trade": {
        "EnterCurrencyName": "Insira um nome de moeda",
        "Currency": "Moeda",
        "LatestPrice": "preço mais recente",
        "Change": "Mudar",
        "Highest": "24H mais alto",
        "Lowest": "24H mais baixo",
        "OrderBook": "livro de pedidos",
        "LatestRecord": "Último registro",
        "All": "Todos",
        "Bids": "lances",
        "Asks": "pergunta",
        "Price": "Preço",
        "Quantity": "Quantidade",
        "Total": "Total",
        "Time": "Tempo",
        "AveragePrice": "Preço médio",
        "EnterPayPass": "Por favor, digite a senha da transação",
        "BuyTips": "Tem certeza que quer comprar?",
        "type": "Tipo"
    },
    "PerpetualContract": {
        "OrderType": "Tipo de pedido",
        "Market": "Mercado",
        "EnterQuantity": "Insira a quantidade de compra",
        "HandlingFee": "Taxa de manuseio",
        "AvailableBalance": "Saldo disponível",
        "Lever": "Alavanca",
        "Fee": "Taxa",
        "OpenLong": "Aberto por muito tempo",
        "OpenShort": "Abra curto",
        "CurrentOrders": "Ordem atual",
        "OrderHistory": "Histórico de pedidos",
        "Margin": "Margem",
        "CurrentPrice": "Preço atual",
        "OpeningPrice": "preço de abertura",
        "UnsettledProfit": "Lucro não liquidado",
        "Operate": "Operar",
        "OpenTime": "Tempo aberto",
        "ClosingTime": "Horário de encerramento",
        "ClosingPrice": "Preço final",
        "SettlementProfit": "Lucro de liquidação",
        "ClosePosition": "Fechar posição",
        "Edit": "Editar",
        "EditOrder": "Editar pedido",
        "EnterCloseQuantity": "Por favor, insira a quantidade",
        "ConfirmOrder": "Confirmar pedido",
        "TakeProfitPrice": "Tirar preço de lucro",
        "StopPrice": "Preço de parada"
    },
    "CurrencyTrading": {
        "Buy": "Comprar",
        "Sell": "Vender",
        "MyBalance": "meu saldo",
        "AvailablePurchaseAmount": "Quantidade disponível para compra",
        "ConvertibleAmount": "Valor conversível",
        "CurrentPrice": "Preço atual",
        "BuyQuantity": "Comprar quantidade",
        "EnterBuyQuantity": "Digite a quantidade para comprar",
        "SellQuantity": "quantidade de venda",
        "EnterSellQuantity": "Digite a quantidade para vender",
        "RequiredAmount": "Quantidade necessária",
        "ExchangeAmount": "Valor da troca",
        "OrderHistory": "Histórico de pedidos",
        "Status": "Status",
        "Success": "Sucesso"
    },
    "SecondContract": {
        "Buy": "Comprar",
        "OpeningQuantity": "quantidade de abertura",
        "EnterOpeningQuantity": "Insira o valor da moeda",
        "SelectQuantity": "Selecione a quantidade",
        "OpeningTime": "Tempo de abertura",
        "minute": "minuto",
        "Balance": "Equilíbrio",
        "Transfer": "Transferir",
        "HandlingFee": "Taxa de manuseio",
        "Profitability": "Lucratividade",
        "BuyUp": "Comprar",
        "BuyDown": "Comprar para baixo",
        "InTransaction": "em transação",
        "Closed": "Fechado",
        "OrderNumber": "Número do pedido",
        "CurrentPrice": "Preço atual",
        "Direction": "Direção",
        "EstimatedProfitLoss": "Lucro e perda estimados",
        "Countdown": "Contagem regressiva",
        "OpeningPrice": "preço de abertura",
        "ClosingPrice": "Preço final",
        "ProfitAndLoss": "Lucros e perdas"
    },
    "Finance": {
        "TopTitle": "Tesouro financeiro",
        "TopDesc": "É uma moeda de retenção de ativos blockchain para ganhar juros, plataforma de investimento de renda estável, incluindo gerenciamento financeiro fixo (bloqueado), gerenciamento financeiro atual e outros tipos de produtos, fornecendo o melhor canal de investimento para usuários de investimento estável",
        "AllProducts": "Todos os produtos",
        "InProgress": "Em andamento",
        "AboutToStart": "Prestes a começar",
        "ItsOver": "Acabou",
        "FinancialRecords": "Recordes financeiros",
        "ReferenceAnnualized": "Referência anualizada",
        "day": "dia",
        "hour": "hora",
        "minute": "minuto",
        "second": "segundo",
        "Unlock": "desbloquear",
        "RemainingPosition": "posição restante",
        "LockedPositions": "Posições bloqueadas",
        "EndOfDistance": "Fim da distância",
        "JoinNow": "Entrar",
        "OurAdvantage": "nossa vantagem",
        "AdvanListTitles": [
            "Renda eficiente",
            "Ciclo mais curto",
            "Seguro e confiável"
        ],
        "AdvanListDescs": [
            "Use uma variedade de combinações de ferramentas financeiras profissionais para criar produtos financeiros de alto rendimento e alta qualidade para os usuários",
            "O ciclo é mais flexível e o bloqueio é de apenas 7 dias",
            "As soluções profissionais de gerenciamento e controle de risco fornecem proteção para retornos de ativos"
        ],
        "JoinNum": "{joinNum} pessoas aderiram",
        "DailyRate": "Diária",
        "StartingTime": "Hora de início",
        "LockPeriod": "período de bloqueio",
        "RebateMethod": "Método de desconto",
        "RebateMethod1": "Retorno principal no vencimento",
        "RebateMethod2": "desconto diário",
        "LockPosition": "posição de bloqueio",
        "ProductSpecification": "Especificação do produto",
        "LockedCurrency": "Moeda bloqueada",
        "SubscriptionAmount": "Valor da assinatura",
        "MinAndMax": "Mínimo/máximo",
        "TotalPosition": "Posição total",
        "TradingRules": "Regras de negociação",
        "BuyProductTips": "Tem certeza de que deseja assinar este produto?",
        "Pending": "Pendente",
        "Completed": "Concluído",
        "ProductName": "Nome do Produto",
        "PurchaseAmount": "Valor da compra",
        "CumulativeIncome": "Renda acumulada",
        "EndTime": "Fim do tempo"
    },
    "Assets": {
        "FinancialRecords": "Recordes financeiros",
        "Deposit": "Depósito",
        "Withdraw": "Retirar",
        "Transfer": "Transferir",
        "TotalBalance": "Balanço total",
        "Refresh": "Atualizar",
        "Currency": "Moeda",
        "AvailableBalance": "Saldo disponível",
        "Frozen": "Congeladas",
        "Operate": "Operar",
        "Type": "Tipo",
        "Time": "Tempo",
        "Quantity": "Quantidade",
        "SelectCurrency": "Escolha a moeda",
        "Address": "Endereço",
        "Notice": "Perceber",
        "DepositRecord": "registro de depósito",
        "OrderNumber": "Número do pedido",
        "Status": "Status",
        "Status1": "Em andamento",
        "Status2": "Concluído",
        "Status3": "Falhar",
        "Status4": "Anormal",
        "Status5": "Cancelado",
        "WithdrawalAmount": "Quantidade retirada",
        "Least": "Ao menos",
        "All": "Todos",
        "WithdrawalCurrency": "Moeda de retirada",
        "WithdrawalAddress": "Endereço de retirada",
        "HandlingFee": "Taxa de manuseio",
        "ReceiptAmount": "Valor do recibo",
        "TransactionPassword": "senha da transação",
        "SetTransactionPassword": "Definir senha da transação",
        "GoogleVerificationCode": "código de verificação do Google",
        "EnterWithdrawalAmount": "Por favor, insira o valor da retirada",
        "EnterWithdrawalAddress": "Por favor, insira o endereço de retirada",
        "EnterTransactionPassword": "Por favor, digite a senha da transação",
        "EnterGoogleVerificationCode": "Insira o código de verificação do Google",
        "WithdrawalsRecord": "Registro de saques",
        "ExchangeCurrency": "Moeda de câmbio",
        "ExchangeAmount": "Valor da troca",
        "ExchangeRate": "Taxa de câmbio",
        "Expected": "Esperado",
        "TransferRecord": "Registro de transferência",
        "CurrencyTransfer": "Moeda para transferir",
        "Success": "Sucesso",
        "EnterExchangeAmount": "Por favor, insira o valor da troca"
    },
    "Account": {
        "Logout": "Sair",
        "PersonalCenter": "centro pessoal",
        "LoginHistory": "histórico de login",
        "Time": "Tempo",
        "Account": "Conta",
        "LoginPassword": "Senha de login",
        "Modify": "Modificar",
        "TransactionPassword": "senha da transação",
        "SetTransactionPassword": "Definir senha da transação",
        "ResetTransactionPassword": "Redefinir senha da transação",
        "Recommend": "recomendar",
        "MobileAuth": "Autenticação móvel",
        "BindMailbox": "Vincular caixa de correio",
        "GoogleAuth": "Autenticação do Google",
        "KYCCertification": "certificação KYC",
        "MobileAuthDesc": "Usado para recuperar senhas e modificar configurações de segurança para verificação de segurança",
        "BindMailboxDesc": "Usado para recuperar senhas e modificar configurações de segurança para verificação de segurança",
        "GoogleAuthDesc": "Usado para sacar moeda para verificação de segurança",
        "Add": "Adicionar",
        "Remove": "Remover",
        "GoAuth": "Ir para autenticação",
        "Verified": "Verificado",
        "UnderReview": "Sob revisão",
        "ChangeLoginPassword": "Alterar senha de acesso",
        "ChangeLoginPasswordTips": "Você não pode sacar dinheiro dentro de 24 horas após a alteração da senha de login, por favor, opere com cuidado",
        "OriginalPassword": "Digite a senha original",
        "NewPassword": "Insira uma nova senha",
        "ConfirmPassword": "Digite a nova senha novamente",
        "EnterOriginalPassword": "Por favor, digite a senha original",
        "EnterNewPassword": "Insira uma nova senha",
        "EnterConfirmPassword": "Digite a nova senha novamente",
        "EnterPasswordError": "O formato da senha é de 6 a 20 caracteres",
        "EnterConfirmPasswordError": "As senhas inseridas duas vezes são inconsistentes",
        "ChangeTransactionPasswordTips": "Você não pode sacar dinheiro dentro de 24 horas após a alteração da senha da transação, por favor, opere com cuidado",
        "EnterLoginPassword": "Digite a senha de acesso",
        "PleaseEnterLoginPassword": "Por favor, insira sua senha",
        "AddGoogleAuth": "Adicionar Google Authenticator",
        "Step1": "Passo 1 Baixe o APP 'Google Authenticator'",
        "Step2": "Etapa 2 Adicionar chave no Google Authenticator e fazer backup",
        "Step3": "Etapa 3 Insira o código de verificação de 6 dígitos do Google Validator",
        "Step1Desc": "Os usuários do iOS fazem login na App Store, procuram por 'Authenticator' para fazer o download<br/><br/> Os usuários do Android fazem login na loja de aplicativos ou usam o navegador móvel para pesquisar 'Google Authenticator' para fazer o download",
        "Step2Desc": "Abra o Google Authenticator, escaneie o código QR abaixo ou insira manualmente a chave abaixo para adicionar um token de verificação",
        "CopyKey": "Copiar chave",
        "EnterGoogleVerificationCode": "Insira o código de verificação do Google",
        "PleaseEnterGoogleVerificationCode": "Insira o código de verificação do Google",
        "RemoveGoogleAuth": "Remover validador do Google",
        "RemoveGoogleAuthTips": "Você não pode sacar dinheiro dentro de 24 horas após a exclusão do Google Validator, por favor, opere com cuidado",
        "UploadIDPhoto": "Carregar foto de identificação",
        "UploadIDPhotoTips": "Carregue uma imagem colorida de todo o documento. Capturas de tela não são permitidas. O tamanho da imagem não pode exceder 10M e só pode estar no formato JPG ou PNG.",
        "FrontPhoto": "foto da frente do cartão de identificação",
        "BackPhoto": "Foto do verso do cartão de identificação",
        "FrontPhotoTips": "Clique para carregar a foto frontal do cartão de identificação",
        "BackPhotoTips": "Clique para carregar uma foto do verso do seu cartão de identificação"
    },
    "FundingType": {
        "All": "Todos",
        "system": "Operação de sistema",
        "commission": "Comissão",
        "recharge": "Recarrega",
        "withdraw": "Retirar",
        "withdrawFail": "Retirada falhou",
        "second_order": "Pedido de contrato de opção",
        "secondOrderWin": "Contrato de opções ganha",
        "perpetual_order": "Depósito de ordem de futuros perpétuos + taxa de manuseio",
        "perpeTualOrderClo": "Fechamento de futuros perpétuos",
        "trade": "Transação de moeda",
        "buyFinance": "Comprar finanças",
        "financeProfit": "Reembolso financeiro",
        "financeMoney": "Financiar de volta principal"
    },
    "Common": {
        "Language": "Linguagem",
        "Tips": "Pontas",
        "Confirm": "confirme",
        "Cancel": "Cancelar",
        "Submit": "Enviar",
        "copy": "cópia de",
        "CopySuccessfully": "Copiado com sucesso",
        "NoData": "sem dados",
        "Loading": "Esperando...",
        "Back": "Voltar",
        "Details": "Detalhes"
    }
}
