export default {
    "Module": {
        "Home": "主页",
        "PerpetualContract": "永续合约",
        "CurrencyTrading": "币币交易",
        "SecondContract": "秒合约",
        "Finance": "理财",
        "Announcement": "公告",
        "Assets": "资产",
        "Account": "账户"
    },
    "Login": {
        "Login": "登录",
        "Register": "注册",
        "RetrievePassword": "找回密码",
        "Type": "电子邮箱",
        "Email": "电子邮箱",
        "Password": "密码",
        "ConfirmPassword": "确认密码",
        "VerificationCode": "验证码",
        "Send": "发送",
        "PhoneNumber": "手机号码",
        "InvitationCode": "邀请码",
        "Agree": "我已阅读并同意",
        "UserAgreement": "用户协议",
        "EnterEmail": "请输入你的电子邮箱",
        "EnterEmailError": "请输入正确的电子邮箱格式",
        "EnterPassword": "请输入登录密码",
        "EnterPasswordError": "密码格式为6到20个字符",
        "EnterConfirmPassword": "请确认你的密码",
        "EnterConfirmPasswordError": "两次输入密码不一致",
        "EnterEmailFirst": "请先输入电子邮箱",
        "EnterVerificationCode": "请输入验证码",
        "EnterPhoneNumber": "请输入你的手机号码",
        "EnterInvitationCode": "请输入邀请码",
        "ConfirmAgreement": "请阅读并同意用户协议",
        "Forget": "忘记密码？"
    },
    "Home": {
        "TcTitle": "消息提示",
        "TopTitle1": "快速开始你的加密货币投资",
        "TopTitle2": "Activtrades是买卖加密货币最值得信赖和最安全的地方",
        "EmailAddress": "电子邮箱地址",
        "StartUsing": "开始使用",
        "SeeMore": "查看更多",
        "MarketTrend": "市场趋势",
        "Currency": "币种",
        "LatestPrice": "最新价格",
        "Change": "24小时改变",
        "TradingVolume": "24小时成交量",
        "Trend": "趋势",
        "Operate": "操作",
        "Trade": "交易",
        "AllTradingVolume": "交易所24小时交易量",
        "SupportedCountries": "支持的国家",
        "RegisterNumber": "注册的用户量",
        "Box4Title": "最值得信赖的加密货币平台",
        "Box4Desc": "以下是您应该选择Activtrades的原因",
        "Box4TabTitles": ["多样化交易", "最低手续费", "领先的安全", "极致用户体验"],
        "Box4TabDescs": [
            "Activtrades提供具有顶级流动性的订单簿，有丰富的加密货币交易及资产管理服务，满足多样化交易需求。",
            "Activtrades通过为交易者提供进入点对点融资市场的途径，选择特定加密货币现货交易对，拥有最低的交易手续费。",
            "Activtrades采用离线存储方式，个人交易数据通过端到端加密获得保障，平台实时监控，全方位保护用户资产安全。",
            "用户至上，简单易用，聆听反馈，产品极速优化，7*24小时实时在线， 多语言一对一专业服务。"
        ],
        "Box5Title": "不断丰富的产品线，满足各类用户需求",
        "Box5TabTitles": ["永续合约", "币币交易", "秒合约", "理财"],
        "Box5TabDescs": [
            "杠杆交易，利用杠杆以自有资金作为保证金，从平台借到N倍于保证金的资金当做本金，进行投资获得利润。",
            "以某种数字货币为计价单位，购买其他种类的数字货币，以最优的价格快速买卖加密货币，获取最佳收益。",
            "金融衍生品，通过分析短期市场行情，交易者在特定时间按预定价格买进或者卖出，快频率进行交易赚取利润。",
            "具有竞争力的高年化收益率， 一流的资金安全保障，轻松赚收益！"
        ],
        "TradeNow": "立即交易",
        "Box6Title1": "随时随地，开启交易",
        "Box6Title2": "APP和WEB，都可以快速开启的交易",
        "FooterItems1": ["条款和政策", "费率标准", "风险警告", "反洗钱", "关于我们"],
        "FooterItems2": ["服务隐私", "服务条款", "隐私声明", "白皮书", "MSB认证"],
        "FooterItems3": ["帮助中心", "新手指引", "操作指南", "联系我们"]
    },
    "Trade": {
        "EnterCurrencyName": "请输入货币名称",
        "Currency": "币种",
        "LatestPrice": "最新价格",
        "Change": "改变",
        "Highest": "24小时最高",
        "Lowest": "24小时最低",
        "OrderBook": "买卖盘",
        "LatestRecord": "最新交易",
        "All": "全部",
        "Bids": "买盘",
        "Asks": "卖盘",
        "Price": "价格",
        "Quantity": "数量",
        "Total": "总计",
        "Time": "时间",
        "AveragePrice": "平均价格",
        "EnterPayPass": "请输入交易密码",
        "BuyTips": "你确定要购买吗？",
        "type": "类型"
    },
    "PerpetualContract": {
        "OrderType": "订单类型",
        "Market": "市场",
        "EnterQuantity": "输入购买数量",
        "HandlingFee": "手续费",
        "AvailableBalance": "可用余额",
        "Lever": "杠杆",
        "Fee": "费用",
        "OpenLong": "开多",
        "OpenShort": "开空",
        "CurrentOrders": "当前订单",
        "OrderHistory": "订单历史",
        "Margin": "保证金",
        "CurrentPrice": "当前价格",
        "OpeningPrice": "开盘价",
        "UnsettledProfit": "未结算利润",
        "Operate": "操作",
        "OpenTime": "开放时间",
        "ClosingTime": "关闭时间",
        "ClosingPrice": "收盘价",
        "SettlementProfit": "结算利润",
        "ClosePosition": "平仓",
        "Edit": "编辑",
        "EditOrder": "编辑订单",
        "EnterCloseQuantity": "请输入数量",
        "ConfirmOrder": "确认订单",
        "TakeProfitPrice": "止盈价",
        "StopPrice": "止损价"
    },
    "CurrencyTrading": {
        "Buy": "买入",
        "Sell": "卖出",
        "MyBalance": "我的余额",
        "AvailablePurchaseAmount": "可购买金额",
        "ConvertibleAmount": "可兑换金额",
        "CurrentPrice": "当前价格",
        "BuyQuantity": "买入数量",
        "EnterBuyQuantity": "输入买入的数量",
        "SellQuantity": "卖出数量",
        "EnterSellQuantity": "输入卖出的数量",
        "RequiredAmount": "所需金额",
        "ExchangeAmount": "兑换金额",
        "OrderHistory": "订单历史",
        "Status": "状态",
        "Success": "成功"
    },
    "SecondContract": {
        "Buy": "买入",
        "OpeningQuantity": "开仓数量",
        "EnterOpeningQuantity": "输入货币数量",
        "SelectQuantity": "选择数量",
        "OpeningTime": "开仓时间",
        "minute": "分钟",
        "Balance": "余额",
        "Transfer": "转移",
        "HandlingFee": "手续费",
        "Profitability": "盈利率",
        "BuyUp": "买涨",
        "BuyDown": "买跌",
        "InTransaction": "交易中",
        "Closed": "已关闭",
        "OrderNumber": "订单号",
        "CurrentPrice": "当前价格",
        "Direction": "方向",
        "EstimatedProfitLoss": "预估损益",
        "Countdown": "倒计时",
        "OpeningPrice": "开仓价",
        "ClosingPrice": "平仓价",
        "ProfitAndLoss": "损益"
    },
    "Finance": {
        "TopTitle": "理财宝",
        "TopDesc": "是一个区块链资产持币生息，稳定收益型投资平台，包括定期（锁仓理财），活期理财等多种类型的产品，为稳健型投资用户提供最佳投资渠道",
        "AllProducts": "全部产品",
        "InProgress": "进行中",
        "AboutToStart": "即将开始",
        "ItsOver": "结束了",
        "FinancialRecords": "理财记录",
        "ReferenceAnnualized": "参考年化",
        "day": "天",
        "hour": "小时",
        "minute": "分钟",
        "second": "秒",
        "Unlock": "解锁",
        "RemainingPosition": "剩余仓位",
        "LockedPositions": "已锁仓位",
        "EndOfDistance": "距离结束",
        "JoinNow": "立即参加",
        "OurAdvantage": "我们的优势",
        "AdvanListTitles": ["高效收益", "周期更短", "安全可靠"],
        "AdvanListDescs": [
            "运用多种专业金融工具组合为用户打造优质的高收益理财产品",
            "周期更灵活，锁仓短至7天",
            "专业的管理及风控方案为资产收益提供保障"
        ],
        "JoinNum": "{joinNum}人已参与",
        "DailyRate": "日利率",
        "StartingTime": "开始时间",
        "LockPeriod": "锁定期",
        "RebateMethod": "返利方式",
        "RebateMethod1": "到期返本",
        "RebateMethod2": "每日返利",
        "LockPosition": "锁定仓位",
        "ProductSpecification": "产品规格",
        "LockedCurrency": "锁定货币",
        "SubscriptionAmount": "订阅金额",
        "MinAndMax": "最小/最大",
        "TotalPosition": "总仓位",
        "TradingRules": "交易规则",
        "BuyProductTips": "你确定要订阅该产品吗？",
        "Pending": "待办的",
        "Completed": "已完成",
        "ProductName": "产品名称",
        "PurchaseAmount": "购买量",
        "CumulativeIncome": "累计收益",
        "EndTime": "结束时间"
    },
    "Assets": {
        "FinancialRecords": "财务记录",
        "Deposit": "存款",
        "Withdraw": "提现",
        "Transfer": "转移",
        "TotalBalance": "总余额",
        "Refresh": "刷新",
        "Currency": "币种",
        "AvailableBalance": "可用余额",
        "Frozen": "冻结的",
        "Operate": "操作",
        "Type": "类型",
        "Time": "时间",
        "Quantity": "数量",
        "SelectCurrency": "选择币种",
        "Address": "地址",
        "Notice": "注意",
        "DepositRecord": "存款记录",
        "OrderNumber": "订单号",
        "Status": "状态",
        "Status1": "进行中",
        "Status2": "已完成",
        "Status3": "失败",
        "Status4": "异常",
        "Status5": "已取消",
        "WithdrawalAmount": "提现金额",
        "Least": "最小",
        "All": "全部",
        "WithdrawalCurrency": "提现币种",
        "WithdrawalAddress": "提现地址",
        "HandlingFee": "手续费",
        "ReceiptAmount": "到账金额",
        "TransactionPassword": "交易密码",
        "SetTransactionPassword": "设置交易密码",
        "GoogleVerificationCode": "谷歌验证码",
        "EnterWithdrawalAmount": "请输入提现金额",
        "EnterWithdrawalAddress": "请输入提现地址",
        "EnterTransactionPassword": "请输入交易密码",
        "EnterGoogleVerificationCode": "请输入谷歌验证码",
        "WithdrawalsRecord": "提现记录",
        "ExchangeCurrency": "兑换币种",
        "ExchangeAmount": "兑换金额",
        "ExchangeRate": "汇率",
        "Expected": "预期",
        "TransferRecord": "转移记录",
        "CurrencyTransfer": "转移币种",
        "Success": "成功",
        "EnterExchangeAmount": "请输入兑换金额"
    },
    "Account": {
        "Logout": "登出",
        "PersonalCenter": "个人中心",
        "LoginHistory": "登录历史",
        "Time": "时间",
        "Account": "账号",
        "LoginPassword": "登录密码",
        "Modify": "修改",
        "TransactionPassword": "交易密码",
        "SetTransactionPassword": "设置交易密码",
        "ResetTransactionPassword": "重置交易密码",
        "Recommend": "推荐",
        "MobileAuth": "移动认证",
        "BindMailbox": "绑定邮箱",
        "GoogleAuth": "谷歌身份验证",
        "KYCCertification": "KYC认证",
        "MobileAuthDesc": "用于检索密码和修改安全设置以进行安全验证",
        "BindMailboxDesc": "用于检索密码和修改安全设置以进行安全验证",
        "GoogleAuthDesc": "用于提取货币以进行安全验证",
        "Add": "添加",
        "Remove": "移除",
        "GoAuth": "去认证",
        "Verified": "已认证",
        "UnderReview": "审核中",
        "ChangeLoginPassword": "修改登录密码",
        "ChangeLoginPasswordTips": "登录密码修改后24小时内不能提现,请谨慎操作",
        "OriginalPassword": "输入原密码",
        "NewPassword": "输入新密码",
        "ConfirmPassword": "再次输入新密码",
        "EnterOriginalPassword": "请输入原密码",
        "EnterNewPassword": "请输入新密码",
        "EnterConfirmPassword": "请再次输入新密码",
        "EnterPasswordError": "密码格式为6到20个字符",
        "EnterConfirmPasswordError": "两次输入密码不一致",
        "ChangeTransactionPasswordTips": "交易密码修改后24小时内不能提现,请谨慎操作",
        "EnterLoginPassword": "输入登录密码",
        "PleaseEnterLoginPassword": "请输入登录密码",
        "AddGoogleAuth": "添加Google Authenticator",
        "Step1": "步骤1 下载“Google Authenticator”APP",
        "Step2": "步骤2 在Google Authenticator中添加密钥并备份",
        "Step3": "步骤3 输入来自Google Validator的6位验证码",
        "Step1Desc": "iOS用户登录App Store,搜索“Authenticator”进行下载<br/><br/>安卓用户登录应用商店或使用手机浏览器搜索“Google Authenticator”进行下载",
        "Step2Desc": "打开Google Authenticator,扫描下方二维码或手动输入下方密钥,添加验证令牌",
        "CopyKey": "复制密钥",
        "EnterGoogleVerificationCode": "输入谷歌验证码",
        "PleaseEnterGoogleVerificationCode": "请输入谷歌验证码",
        "RemoveGoogleAuth": "刪除Google Validator",
        "RemoveGoogleAuthTips": "刪除Google Validator后24小时内不能提现,请谨慎操作",
        "UploadIDPhoto": "上传身份证照片",
        "UploadIDPhotoTips": "上传整个文档的彩色图像。不允许截图。图片大小<br/>不能超过10M,只能是JPG或PNG格式。",
        "FrontPhoto": "身份证正面照片",
        "BackPhoto": "身份证反面照片",
        "FrontPhotoTips": "点击上传身份证正面照片",
        "BackPhotoTips": "点击上传身份证反面照片"
    },
    "FundingType": {
        "All": "全部",
        "system": "系统操作",
        "commission": "佣金",
        "recharge": "充值",
        "withdraw": "提现",
        "withdrawFail": "提现失败",
        "second_order": "秒合约下单",
        "secondOrderWin": "秒合约赢单",
        "perpetual_order": "永续合约下单保证金+手续费",
        "perpeTualOrderClo": "永续合约闭仓",
        "trade": "币币交易",
        "buyFinance": "购买理财",
        "financeProfit": "理财返利",
        "financeMoney": "理财返还本金"
    },
    "Common": {
        "Language": "语言",
        "Tips": "提示",
        "Confirm": "确认",
        "Cancel": "取消",
        "Submit": "提交",
        "copy": "复制",
        "CopySuccessfully": "复制成功",
        "NoData": "没有数据",
        "Loading": "等待中...",
        "Back": "返回",
        "Details": "详情"
    }
}